<template>
    <div>
        <b-row>
            <b-col md="6">
                <h6 class="mb-2">{{ $t('student_list_with_photo') }}</h6>
            </b-col>
            <b-col md="6" class="d-flex justify-content-end">
                <b-button variant="primary" class="mb-2" @click="print">{{ $t('print') }}</b-button>
            </b-col>
        </b-row>
        <div id="printMe">
            <div class="col-12 border rounded-sm p-4 mb-4">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <b-form-group :label="$t('academic_year')">
                            {{ students[0] ? students[0].academic_year : '-' }}
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <b-form-group :label="$t('period')">
                            {{ students[0] ? students[0].semester : '-' }}
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <b-form-group :label="$t('course')">
                            {{ coverLetter.course_code + ' - ' + getLocaleText(coverLetter, 'course_name') }}
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                        <b-form-group :label="$t('section')">
                            {{ sectionNo }}
                        </b-form-group>
                    </div>
                </div>

            </div>

            <b-row>
                <b-card-group deck>
                    <div v-for="(item, key) in students" class="col-12 col-sm-6 col-md-4 col-lg-4" :key="key">
                        <b-card :img-src="item.photo ? item.photo : require('@/assets/img/no-photo.jpg')"
                                :img-alt="item.name + ' ' + item.surname" img-top tag="article" style="max-width: 20rem;"
                                class="mb-4">
                            <b-card-title class="mb-1">
                                {{ item.student_number }}
                            </b-card-title>
                            <b-card-text>
                                {{ item.name + ' ' + item.surname }} <br />
                            </b-card-text>
                        </b-card>
                    </div>
                </b-card-group>
            </b-row>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <b-button variant="primary" @click="goBack">{{ $t('back').toUpper() }}</b-button>
        </div>
    </div>
</template>
<script>
import StudentService from "@/services/StudentService";
import qs from "qs";

export default {
    name: "StudentsWithPhoto",
    props: {
        courseId: {
            default: null,
            type: Number
        },
        semesterId: {
            default: '0',
            type: String
        },
        sectionNo: {
            default: 0,
            type: Number
        },
        coverLetter: {
            default: null,
            type: Object
        }
    },
    data() {
        return {
            students: [],
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {

        getData() {
            let data = { semester_id: this.semesterId, course_id: this.courseId, section: this.sectionNo, with_photo: 1}

            let config = {
                params: data,
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            StudentService.getInstructorStudents(config).then(response => {
                response.data.data.forEach(item => {
                    this.students.push({
                        student_number: item.student_number,
                        name: item.name,
                        surname: item.surname,
                        letter_grade: item.letter_grade,
                        photo: item.photo,
                        semester: this.getLocaleText(item, 'semester_name'),
                        academic_year: item.academic_year
                    })
                })

            })
        },

        goBack() {
            this.$emit('back')
        },

        print() {
            const options = {
                autoClose:false
            }
            this.$htmlToPaper('printMe',options)
        }
    },

    created() {
        this.getData()
    },

    watch: {
        courseId: {
            handler: function (val) {
                this.getData(val)
            }
        },
        semesterId: {
            handler: function (val) {
                this.getData(val)
            }
        },
        sectionNo: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>
